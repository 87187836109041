import { getJSON } from '../http';
import { logger } from '../sentry/console';
import { AdType } from '../types';

import { RequestParamsProvider } from './RequestParamsProvider';

declare global {
  interface Window {
    canRunAds: boolean;
    canRunAdvertise: boolean;
    hpNativeConfig: {
      customCount: boolean;
      rows: number;
      itemsPerRow: number;
    };
  }
}

interface ApiOptions {
  baseUrl: string;
}

interface IRequestParams {
  page: string;
  referrer: string;
  sessionId: string;
  adblock: { canRunAdvertise: boolean; canRunAds: boolean };
  frame: boolean;
  timezone: number;

  withCreatives(creatives: string[]): void;
  toString(): string;
}

export interface IApiZone {
  id: number;
  code: string;
  source: string;
  type: AdType;
  tr?: string;
  creative?: string;
  mismatch?: boolean;
}

export interface IApiZoneById {
  [key: number]: IApiZone;
}

/**
 * Api class used to fetch data about zones
 *
 * @param opts {ApiOptions interface}
 */
export default class Api {
  private requestParams: IRequestParams;
  private readonly baseUrl: string;

  constructor(opts: ApiOptions) {
    this.requestParams = new RequestParamsProvider();
    this.baseUrl = opts.baseUrl;
  }

  async fetchZones(
    zoneIds: number[],
    creativeIds: string[] = []
  ): Promise<IApiZoneById | undefined> {
    if (!zoneIds || zoneIds.length === 0) return undefined;
    this.requestParams.withCreatives(creativeIds);
    const params = this.requestParams.toString();
    let url = this.baseUrl + '/resource?';
    url = url + `zones=${zoneIds.join(';')}`;
    url = url + `&${params}`;
    url = url + `&code=1`;
    url = url + `&json=1`;

    return new Promise(
      function (resolve, reject) {
        try {
          getJSON(url, (response) => {
            // Object.keys(response).forEach((id) => {
            //   response[id].code = response[id].code.replace(
            //     '{params}',
            //     '&' + this.requestParams.toString() + '{params}'
            //   );
            //   response[id].source = response[id].source.replace(
            //     '{params}',
            //     '&' + this.requestParams.toString() + '{params}'
            //   );
            // });

            // logger.log('ZONES RESPONSE:');
            // logger.table(response);

            resolve(response);
          });
        } catch (error) {
          logger.error(error);
          reject(error);
        }
      }.bind(this)
    );
  }
}

// export const collectBotManagement = () => {
//   try {
//     if (sessionStorage.getItem('hp-collect')) {
//       return;
//     }
//     get('https://hd100546c.com/collect', function () {
//       sessionStorage.setItem('hp-collect', String(1));
//     });
//     // eslint-disable-next-line no-empty
//   } catch (e) {}
// };
//
// collectBotManagement();
