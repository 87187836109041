import { ExtendedError } from '@sentry/types';

import { RequestParamsProvider } from './api/RequestParamsProvider';
import { RemoteLogger } from './sentry';
import { throttle } from './utils/throttle';

export interface trackItem {
  el: HTMLElement;
  tr: string;
}

export default class Tracker {
  static trackerImgStyles =
    'display:block;width:1px;height:1px;opacity:0;overflow:hidden;margin-bottom:-1px;';
  io: IntersectionObserver;
  private trackList: trackItem[] = [];
  static requestParams = new RequestParamsProvider();
  private static instance: Tracker;

  constructor() {
    this.checkInViewport = throttle(
      this.checkInViewport.bind(this),
      100
    ) as () => void;

    document.addEventListener('scroll', this.checkInViewport, false);
  }

  isInViewport(el, percentVisible) {
    const rect = el.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return !(
      Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / -rect.height) * 100) <
        percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
        percentVisible
    );
  }

  checkInViewport() {
    for (let i = 0; i < this.trackList.length; i++) {
      if (!this.isInViewport(this.trackList[i].el, 50)) continue;

      const trImgSrc =
        this.trackList[i].tr +
        RequestParamsProvider.addTrackingParams(
          Tracker.requestParams.toString()
        );
      this.renderTracker(trImgSrc);
      this.trackList.splice(i, 1);
    }
  }

  renderTracker(source: string) {
    const img = document.createElement('img');

    function trLoaded() {
      img.parentNode.removeChild(img);
    }

    img.style.cssText = Tracker.trackerImgStyles;
    img.onload = img.onerror = trLoaded;
    img.src = source;
    try {
      document.body.appendChild(img);
    } catch (error) {
      const newError = new Error(
        'body element: \n' + document.body + 'tracker source: \n' + source
      ) as ExtendedError;
      newError.cause = error;

      RemoteLogger.getInstance().captureEvent(newError);
    }
  }

  addItemToTrackList(item: trackItem) {
    this.trackList.push(item);
    this.checkInViewport();
  }

  public static getInstance(): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker();
    }

    return Tracker.instance;
  }
}
