/**
 * Get parameters required by adserver
 */
import { AdType } from '../types';

import { RequestParamsProvider } from './RequestParamsProvider';

export class RequestParamsProviderFactory {
  static NewRequestParamsProvider(
    name: AdType | undefined
  ): RequestParamsProvider {
    const provider = new RequestParamsProvider();

    if (name) provider.withImpressionNumberKey(name);

    return provider;
  }
}
