import {
  getQueryParams,
  IQueryParams,
  isSessionStorageSupported,
} from './utils';

/**
 * Class to save and use UTM params while sessions is going.
 */

export class Utm {
  private readonly currentSearchParams: IQueryParams;
  private readonly utmState: string[];

  constructor() {
    const utmParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ];

    this.currentSearchParams = getQueryParams(window.location.search);
    this.utmState = [];

    for (let i = 0; i < utmParams.length; i++) {
      const paramName = utmParams[i];
      const param =
        this.currentSearchParams[paramName] ||
        this.getFromSessStorage(paramName);
      if (param) {
        this.setSearchToSessStorage(paramName, param);
        this.utmState.push(paramName + '=' + param);
      }
    }
  }

  getUtmState() {
    return this.utmState;
  }

  setSearchToSessStorage(key, val) {
    if (isSessionStorageSupported()) {
      window.sessionStorage.setItem(key, val);
    }
  }

  getFromSessStorage(key) {
    if (isSessionStorageSupported()) {
      return (
        window.sessionStorage.getItem(key) || this.currentSearchParams[key]
      );
    } else {
      return this.currentSearchParams[key];
    }
  }
}
