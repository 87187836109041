/**
 * Get parameters required by adserver
 */
import { Storage } from '../storage';
import { AdType } from '../types';
import { generateUuidv4, getAdBlock } from '../utils';
import { Utm } from '../utm';

export class RequestParamsProvider {
  utmSession: Utm;
  _impressionNumberKey: AdType = null;
  _creatives: string[];

  constructor(creatives = []) {
    this.utmSession = new Utm();
    this._creatives = [...creatives];
  }

  withImpressionNumberKey(name: AdType): RequestParamsProvider {
    this._impressionNumberKey = name;
    return this;
  }

  get page(): string {
    return window.location.href;
  }

  get referrer(): string {
    return document.referrer;
  }

  get sessionId(): string {
    let uid = '';
    const ca = decodeURIComponent(document.cookie).split(';');
    // @ts-ignore
    const myCrypto = window.crypto || window.msCrypto;
    const cookName = '__ae_uid_sess_id=';

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      let n = 0;

      while (c.charAt(n) === ' ') {
        n++;
      }

      if (n > 0) {
        c = c.substring(n);
      }

      if (c.indexOf(cookName) === 0) {
        uid = c.substring(cookName.length, c.length);
      }
    }

    if (uid.length === 0) {
      if (myCrypto) {
        // @ts-ignore
        uid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          function (c) {
            return (
              c ^
              (myCrypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16);
          }
        );
      } else {
        uid = generateUuidv4();
      }
    }

    document.cookie = cookName + uid + ';';
    return uid;
  }

  get adblock(): { canRunAdvertise: boolean; canRunAds: boolean } {
    return {
      canRunAdvertise: window.canRunAdvertise,
      canRunAds: window.canRunAds,
    };
  }

  get frame(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  get timezone(): number {
    return new Date().getTimezoneOffset();
  }

  get impressionNumber(): number {
    if (!this._impressionNumberKey) return 0;

    let num = +Storage.getCookie(`imp-num-${this._impressionNumberKey}`);

    if (isNaN(num)) {
      num = 1;
    }

    return num;
  }

  incrementImpressionNumber(): void {
    if (!this._impressionNumberKey) return;
    Storage.setCookie(
      `imp-num-${this._impressionNumberKey}`,
      this.impressionNumber + 1
    );
  }

  toString(): string {
    const params = [...this.utmSession.getUtmState()];
    params.push(`p=${encodeURIComponent(this.page)}`);
    params.push(`r=${encodeURIComponent(this.referrer)}`);
    params.push(`sid=${this.sessionId}`);
    params.push(`frm=${+this.frame}`);
    params.push(`tz=${this.timezone}`);

    // three parameter were created to check different methods of adblock detection
    // adblk, ablk, a
    params.push(`adblk=${+!this.adblock.canRunAds}`);
    params.push(`ablk=${+!this.adblock.canRunAdvertise}`);
    params.push(`a=${+!getAdBlock()}`);

    if (this._impressionNumberKey) {
      params.push(`num=${this.impressionNumber}`);
    }

    if (this._creatives.length > 0) {
      params.push(`&cr=${this._creatives.join(';')}`);
    }

    return params.join('&');
  }

  withCreatives(creatives: string[]) {
    this._creatives = creatives;
  }

  static addTrackingParams(url: string): string {
    return `${url}&n=${Date.now()}`;
  }

  withNativeParams(url: string): string {
    let newUrl = url;

    if (window?.hpNativeConfig?.customCount) {
      if (window.hpNativeConfig.rows && window.hpNativeConfig.itemsPerRow) {
        newUrl +=
          '&nrc=' +
          window.hpNativeConfig.rows +
          '&npr=' +
          window.hpNativeConfig.itemsPerRow;
      }
    }

    return newUrl;
  }
}
