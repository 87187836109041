import { Breadcrumb, Client, EventHint, Hub } from '@sentry/types';

import { logger } from './console';
import defaultOptions from './default-options';

declare global {
  interface Window {
    Sentry: Client & Hub & { init };
  }
}

export class RemoteLogger {
  static instance = null;
  static breadcrumbs: Breadcrumb[];
  permissions = [];
  readyPromise = null;

  constructor() {
    this.setupGlobalErrorHandlers();
  }

  setupGlobalErrorHandlers() {
    const oldOnError = window.onerror;

    const handleRejection = (e: Error) => {
      this.loadSentry().then(() => {
        this.captureEvent(e);
      });
    };

    // unhandledrejection fires in unhandled promise rejections
    // https://stackoverflow.com/a/28004999/5437309
    window.addEventListener(
      'unhandledrejection',
      function (promiseRejectionEvent) {
        promiseRejectionEvent.promise.catch((e) => {
          handleRejection(e);
        });
      }
    );

    window.onerror = function (
      message: string | Event,
      url: string,
      line: number,
      col: number,
      e: Error
    ) {
      oldOnError && oldOnError(message, url, line, col, e);

      handleRejection(e);
    }.bind(this);
  }

  captureEvent(error: Error) {
    RemoteLogger.getInstance()
      .loadSentry()
      .then((ok) => {
        if (!ok) {
          return;
        }
        RemoteLogger.breadcrumbs.forEach((breadcrumb) => {
          window.Sentry.addBreadcrumb(breadcrumb);
        });
        RemoteLogger.breadcrumbs.length = 0;
        window.Sentry.captureException(error);
      })
      .catch((e) => {
        logger.warn('error happened while sending a sentry report: ', e);
      });
  }

  static addBreadcrumb(breadcrumb: Breadcrumb) {
    if (window.Sentry) {
      window.Sentry.addBreadcrumb(breadcrumb);
      return;
    }

    RemoteLogger.breadcrumbs.push(breadcrumb);
  }

  addPermissionChecker(permission) {
    if (typeof permission === 'function') {
      this.permissions.push(permission);
    }
  }

  checkSendingPermission(event, exp) {
    let valid = true;

    for (let i = 0; i < this.permissions.length; i++) {
      if (!valid) {
        return valid;
      }
      valid = this.permissions[i](event, exp);
    }
    return valid;
  }

  createSentryScript(
    src = 'https://browser.sentry-cdn.com/6.16.1/bundle.tracing.min.js',
    integrity = 'sha384-hySah00SvKME+98UjlzyfP852AXjPPTh2vgJu26gFcwTlZ02/zm82SINaKTKwIX2'
  ): HTMLScriptElement {
    const sentryScript = document.createElement('script');
    sentryScript.src = src;
    sentryScript.integrity = integrity;
    sentryScript.setAttribute('crossorigin', 'anonymous');

    return sentryScript;
  }

  async loadSentry() {
    if (this.readyPromise) return this.readyPromise;
    if (window.Sentry) {
      this.createSentry();
      return Promise.resolve(true);
    }

    const sentryScript = this.createSentryScript(
      process.env.SENTRY_LIB,
      process.env.SENTRY_INTEGRITY
    );
    this.readyPromise = new Promise<boolean>((resolve) => {
      sentryScript.onload = function () {
        this.createSentry();
        resolve(true);
      }.bind(this);
      sentryScript.onerror = function () {
        resolve(false);
      };
    });

    document.body.appendChild(sentryScript);

    return this.readyPromise;
  }

  createSentry() {
    window.Sentry.init({
      ...defaultOptions,
      beforeSend: (event, hint: EventHint) => {
        if (
          hint?.originalException instanceof Error &&
          hint?.originalException?.stack
        ) {
          const exp = hint.originalException.stack;
          if (this.checkSendingPermission(event, exp)) {
            logger.error(
              'HANDLED ERROR: ',
              hint,
              '\n',
              'REPORT RATE: ',
              `${parseFloat(process.env.SENTRY_SAMPLE_RATE) * 100}%`
            );
            return event;
          }
        }

        return null;
      },
    });
  }

  static getInstance() {
    if (RemoteLogger.instance === null) {
      RemoteLogger.instance = new RemoteLogger();
    }

    return RemoteLogger.instance;
  }
}
