export interface IQueryParams {
  [key: string]: string;
}

export function getQueryParams(qs: string): IQueryParams {
  qs = qs.split('+').join(' ');

  const params: IQueryParams = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;

  let tokens;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export function isSessionStorageSupported() {
  const storage = window.sessionStorage;
  try {
    storage.setItem('test', 'test');
    storage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
}

export function generateUuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function checkLocalStorage() {
  try {
    localStorage.setItem('chk', 'chk');
    localStorage.removeItem('chk');
    return true;
  } catch (e) {
    return false;
  }
}

let getAdblockCache: boolean | null = null;

export function getAdBlock(): boolean {
  if (getAdblockCache !== null) return getAdblockCache;

  const ads = document.createElement('div');
  ads.innerHTML = '&nbsp;';
  ads.className =
    'ad ads adsbox doubleclick ad-placement ad-placeholder adbadge BannerAd';
  getAdblockCache = false;
  try {
    // body may not exist, that's why we need try/catch
    document.body.appendChild(ads);
    getAdblockCache =
      (
        document.getElementsByClassName(
          'ad ads adsbox doubleclick ad-placement ad-placeholder adbadge BannerAd'
        ) as HTMLCollectionOf<HTMLElement>
      )[0].offsetHeight === 0;
    document.body.removeChild(ads);
  } catch (e) {
    getAdblockCache = false;
  }
  return getAdblockCache;
}

export function onDOMLoaded(callback) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    callback();
  }
}

export function makeCustomPublicPath() {
  const hpRdr = document.getElementById('hpt-rdr');
  const customPublicPath = hpRdr?.getAttribute('data-static-path');

  if (customPublicPath) {
    __webpack_public_path__ = customPublicPath + '/rdrr/';
  }
}
