import { adInserterFactory } from './ad-type-factory';
import { TextLink } from './ad-type-renderers/text-link';
import { DirectPaidTrafficTracker } from './api/DirectPaidTrafficTracker';
import Api, { IApiZone, IApiZoneById } from './api/api';
import { AdvertisingDOM, AdvertisingDOMSingleton, DOM } from './dom';
import { logger } from './sentry/console';

declare global {
  interface Window {
    hpRdrConfig: {
      ignoreIfNotVisible: boolean;
      showIfMismatch: boolean;
    };
  }
}

export class Renderer {
  private api: Api;
  private dom: AdvertisingDOM = AdvertisingDOMSingleton.getInstance();
  private isRendering = false;
  private ignoreIfNotVisible = false;
  private showIfMismatch = false;

  constructor() {
    let baseUrl = 'https://hip-97166b.com';

    const hpRdr = document.getElementById('hpt-rdr');
    if (hpRdr) {
      baseUrl = hpRdr.getAttribute('data-hpt-url')
        ? 'https://' + hpRdr.getAttribute('data-hpt-url')
        : baseUrl;
    }

    this.api = new Api({
      baseUrl: baseUrl,
    });

    this.ignoreIfNotVisible = !!window.hpRdrConfig?.ignoreIfNotVisible;
    this.showIfMismatch = !!window.hpRdrConfig?.showIfMismatch;

    new DirectPaidTrafficTracker();
  }

  private startRendering() {
    this.isRendering = true;
    this.dom.updateSpots();
  }

  private endRendering() {
    this.isRendering = false;
  }

  public async render(): Promise<void> {
    if (this.isRendering) {
      logger.warn('render is going. call was declined');
      return;
    }

    this.startRendering();

    if (this.dom.spotsExists) {
      logger.debug('there is no spots to render');
      this.endRendering();
      return;
    }

    const zones = await this.api.fetchZones(
      this.dom.spotIds,
      this.dom.creativeIds
    );

    const { predicate: insertPredicate } = new InsertPredicate(
      zones,
      this.ignoreIfNotVisible,
      this.showIfMismatch
    );

    this.dom.spotIds.forEach((id) => {
      if (!insertPredicate(id)) {
        logger.debug(`skip zone ${id}: `, zones[id]);
        return;
      }

      this.insertZone({
        ...zones[id],
        id: id,
        creative: this.dom.creativeIdByZoneId(String(id)),
      } as IApiZone);
    });

    TextLink.invoke();

    this.endRendering();
  }

  /**
   *
   * @param zone IApiZone
   *
   * insert zone from adserver to page
   */
  private async insertZone(zone: IApiZone): Promise<void> {
    if (!zone.id) return;

    const spot = this.dom.spotElementById(String(zone.id), true);

    if (!spot) {
      logger.debug(`zone with id: ${zone.id} is not defined`);
      return;
    }

    (await adInserterFactory(zone)).insert(spot);
    window.dispatchEvent(this.dom.renderEvent);
  }

  public update(): Promise<void> {
    return this.render();
  }
}

type Predicate = (id: number) => boolean;

class InsertPredicate extends DOM {
  _zones: IApiZoneById = null;

  private readonly _predicates: Array<Predicate> = [];
  predicate = (id: number): boolean => {
    for (let i = 0; i < this._predicates.length; i++) {
      const ok = this._predicates[i](id);
      if (!ok) {
        return false;
      }
    }

    return true;
  };

  constructor(
    zones: IApiZoneById,
    ignoreIfNotVisible: boolean,
    showIfMismatch: boolean
  ) {
    super();

    this._zones = zones;

    this._predicates.push(
      function (id: number) {
        // insert if true
        return !!this._zones[id];
      }.bind(this)
    );

    if (ignoreIfNotVisible) {
      this._predicates.push(
        function (id: number) {
          return this.isVisible(document.querySelector(`[data-hp-id='${id}']`));
        }.bind(this)
      );
    }

    if (!showIfMismatch) {
      this._predicates.push(
        function (id: number) {
          return !this._zones[id].mismatch;
        }.bind(this)
      );
    }
  }
}
