import { RequestParamsProvider } from '../api/RequestParamsProvider';
import { RequestParamsProviderFactory } from '../api/RequestParamsProviderFactory';
import { IApiZone } from '../api/api';
import { impressionNumberState } from '../experiment';
import Tracker from '../tracker';
import { AdType } from '../types';

import { Zone } from './zone';

export class Banner extends Zone {
  private zone: IApiZone;
  private static tracker = Tracker.getInstance();
  private static requestParams =
    RequestParamsProviderFactory.NewRequestParamsProvider(
      (impressionNumberState && AdType.Html) || undefined
    );

  constructor(zone: IApiZone) {
    super();

    this.zone = zone;
  }

  public insert(spot: HTMLElement) {
    this.zone.creative &&
      Banner.requestParams.withCreatives([this.zone.creative]);
    const container = this.dom.createElement(
      this.zone.code.replace('{params}', '&' + Banner.requestParams.toString())
    ).children[0] as HTMLElement;

    this.dom.replaceChild(container, spot);

    Banner.requestParams.incrementImpressionNumber();
    Banner.tracker.addItemToTrackList({
      el: container,
      tr: this.zone.tr.replace(
        '{params}',
        RequestParamsProvider.addTrackingParams(Banner.requestParams.toString())
      ),
    });

    Banner.requestParams.withCreatives([]);
    // logger.debug(`insert code: ${this.zone.code} for zoneId: ${this.zone.id}`);
    return;
  }
}
