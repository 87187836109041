import { IApiTextLinkZone } from './i-zone';
import { Zone } from './zone';

export class TextLink extends Zone {
  private zone: IApiTextLinkZone;

  constructor(zone: IApiTextLinkZone) {
    super();
    this.zone = zone;
  }

  insert(spot: HTMLElement): void {
    let spotTemplate =
      "<a href='{url}' class='top-link' target='_blank'>{content}</a>";
    let fullUrl = this.zone.source;
    if (fullUrl.indexOf('p=') === -1) {
      const sep = fullUrl.indexOf('?') === -1 ? '?' : '&';
      fullUrl =
        this.zone.source +
        sep +
        'p=' +
        encodeURIComponent(window.location.href) +
        sep +
        'cr=' +
        this.zone.creative;
    }
    spotTemplate = spotTemplate.replace('{url}', fullUrl);
    spotTemplate = spotTemplate.replace('{content}', this.zone.config.title);
    spot.insertAdjacentHTML('afterbegin', spotTemplate);

    this.dom.cleanupSpotAttrs(spot);
  }

  static invoke(): void {
    const textLinks = document.getElementsByClassName(
      'js-hp-tl'
    ) as HTMLCollectionOf<HTMLAnchorElement>;
    if (textLinks.length === 0) return;

    const p = 'p=' + encodeURIComponent(window.location.href);

    for (let i = 0; i < textLinks.length; i++) {
      let url = null;
      let nestedLink = null;

      if (textLinks[i].href) {
        url = textLinks[i].href;
      } else {
        const link = textLinks[i].getElementsByTagName('a');
        if (link.length && link[0].href) {
          nestedLink = link[0];
          url = link[0].href;
        } else {
          continue;
        }
      }

      if (url.indexOf('p=') === -1) {
        const prefix = url.indexOf('?') === -1 ? '?' : '&';

        if (nestedLink === null) {
          textLinks[i].href = url + prefix + p;
        } else {
          nestedLink.href = url + prefix + p;
        }
      }
    }
  }
}
