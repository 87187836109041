import 'regenerator-runtime/runtime';
import './polyfill';
import { Renderer } from './renderer';
import { RemoteLogger, runSentry } from './sentry';
import { makeCustomPublicPath, onDOMLoaded } from './utils';

function injectRenderer(r: Renderer) {
  if (window['hptRdr'] instanceof Renderer) {
    return;
  }
  window['hptRdr'] = r;
}

(function () {
  makeCustomPublicPath();

  onDOMLoaded(async function () {
    runSentry();

    try {
      const r = new Renderer();
      injectRenderer(r);
      await r.render();
    } catch (e) {
      RemoteLogger.getInstance().captureEvent(e);
    }
  });
})();

export {};
